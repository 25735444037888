/*

  Datepicker
  https://flatpickr.js.org/getting-started/

 */

import flatpickr from "flatpickr";
import moment from "moment";
import 'moment/locale/ja';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_blue.css';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';
flatpickr.localize(Japanese);

$(function(){
    flatpickrSetting();
    flatpickrInputSetting();

    $(document).on('cocoon:after-insert', '.select2_fields', (e, elm) => {
        flatpickrSetting();
        flatpickrInputSetting();
    })

    function flatpickrSetting() {
      flatpickr('.flatpickr', {
          clickOpens: false,
          altInput: false,
          altFormat: 'YYYY-MM-DD',
          dateFormat: "YYYY-MM-DD",
          parseDate: (datestr, format) => {
              return moment(datestr, format, true).toDate();
          },
          formatDate: (date, format, locale) => {
              // locale can also be used
              return moment(date).format(format);
          },
          allowInput: true,
          wrap: true,
          disableMobile: true,
      });
    }

    function seireki(warekiYear) {
        var matches = warekiYear.match('^(明治|大正|昭和|平成|令和)([元0-9０-９]+)$');
        if(matches) {
            var eraName = matches[1];
            var year = parseInt(matches[2].replace(/[元０-９]/g, function(match){
                if(match === '元') {
                    return 1;
                }
                return String.fromCharCode(match.charCodeAt(0) - 65248);
            }));
            if(eraName === '明治') {
                year += 1867;
            } else if(eraName === '大正') {
                year += 1911;
            } else if(eraName === '昭和') {
                year += 1925;
            } else if(eraName === '平成') {
                year += 1988;
            } else if(eraName === '令和') {
                year += 2018;
            }
            return year;
        }
        return null;
    }

    function flatpickrInputSetting() {
      $('.flatpickr-input').on('change',function(){
        let s = $(this).val();
        const date_regex = /(\d{4})\/(\d{1,2})\/(\d{1,2})/
        const date_regex_jp = /(\D+)+(\d+)年(\d+)月(\d+)日/
        if (s.match( date_regex )) {
          let r = s.match( date_regex );
          r[2] = ('0' + r[2]).slice(-2);
          r[3] = ('0' + r[3]).slice(-2);
          let result = r[1] + '-' + r[2] + '-' + r[3];
          let dt = new Date(r[1], r[2]-1, r[3] );
          $(this).val(result);
          let warekiElement = document.createElement('p');
          warekiElement.className = 'wareki';
          warekiElement.textContent = dt.toLocaleDateString('ja-JP-u-ca-japanese', {era:'long'});
          $(this).parent().parent().find('.wareki').remove();
          $(this).parent().parent().append(warekiElement);
        } else if (s.match( date_regex_jp )) {
          let r = s.match( date_regex_jp );
          r[3] = ('0' + r[3]).slice(-2);
          r[4] = ('0' + r[4]).slice(-2);
          let wa = r[1] + r[2];
          let tmp = seireki(wa);
          let result = tmp + '-' + r[3] + '-' + r[4];
          let dt = new Date(r[1], r[2]-1, r[3] );
          $(this).val(result);
          let warekiElement = document.createElement('p');
          warekiElement.className = 'wareki';
          warekiElement.textContent = dt.toLocaleDateString('ja-JP-u-ca-japanese', {era:'long'});
          $(this).parent().parent().find('.wareki').remove();
          $(this).parent().parent().append(warekiElement);
        } else if (s.match( date_regex )) {
          let r = s.match( date_regex);
          r[2] = ('0' + r[2]).slice(-2);
          r[3] = ('0' + r[3]).slice(-2);
          let result = r[1] + '-' + r[2] + '-' + r[3];
          let dt = new Date(r[1], r[2]-1, r[3] );
          $(this).val(result);
          let warekiElement = document.createElement('p');
          warekiElement.className = 'wareki';
          warekiElement.textContent = dt.toLocaleDateString('ja-JP-u-ca-japanese', {era:'long'});
          $(this).parent().parent().find('.wareki').remove();
          $(this).parent().parent().append(warekiElement);
        } else {
          $(this).parent().parent().find('.wareki').remove();
        }
      });
    }

    flatpickr('.jstp', {});
    flatpickr('#arr-cal', {
        onClose: function(selectedDates, dateStr, instance){
            if (dateStr) {
                location.href = "?date=" + dateStr;
            }
        }
    });
})
