/*

 for searching name in select tag
 https://select2.org/

 */

require('Select2/select2.css')
require('Select2');
require('Select2/select2_locale_ja')

$(function(){
    $('.person-in-charge-selector').select2({
        allowClear: true
    });
    $('.company-selector').select2({
        allowClear: true
    });
    $(document).on('cocoon:after-insert cocoon:after-remove', '.select2_fields', (e, elm) => {
        elm.find('select.company-selector').select2({allowClear: true});
        qualificationDelete();
    });
    $(document).on('cocoon:after-insert cocoon:after-remove', '.default_select2_fields', (e, elm) => {
        elm.find('select.company-selector').select2({allowClear: true});
    });
    $('.prefecture-selector').select2({
        allowClear: true
    });
    $('.gender-selector').select2({
        allowClear: true
    });
    $('.country-selector').select2({
        allowClear: true
    });
    $('.selector').select2({
        dropdownAutoWidth: true,
        width: 'resolve',
        allowClear: true
    });
    $('.selector').on('select2-opening', function(e) {
        if ($(this).closest('.js_labor_cost').length > 0) {
            changeColorCostManagement($(this));
        }
    });
    $('.js_labor_cost').on('cocoon:after-insert', function() {
        $('.selector').on('select2-opening', function(e) {
            changeColorCostManagement($(this));
        });
        $('.table-column-sticky').on('click', '.js-color-fuga', function() {
            changeColorCostManagement($(this));
        });
    });
    $(document).on('cocoon:after-insert cocoon:after-remove', '.select2_fields', (e, elm) => {
        elm.find('select.selector').select2({
            dropdownAutoWidth: true,
            width: 'resolve',
            allowClear: true
        });
        qualificationDelete();
    });
    $(document).on('cocoon:after-insert cocoon:after-remove', '.default_select2_fields', (e, elm) => {
        elm.find('select.selector').select2({
            dropdownAutoWidth: true,
            width: 'resolve',
            allowClear: true
        });
    });

    $("body").on('click', '.add_worker', function() {
        const parent_id = $(this).attr('data-id');
        const cost_arrange_index = $(this).attr('data-index');
        const worker_index = Date.now();
        const contents = $('#cost_arrange_worker_template').html().replaceAll("cost_arrange_index", cost_arrange_index).replaceAll("worker_index", worker_index);
        const select_id = "#cost_arranges_" + cost_arrange_index + "_cost_arrange_workers_" + worker_index + "_worker_id";
        $(parent_id).append(contents);
        $(select_id).select2({
            allowClear: true
        });
    })

    $("body").on('click', '.add_outsourcings', function() {
        const parent_id = $(this).attr('data-id');
        const cost_arrange_index = $(this).attr('data-index');
        const key = Date.now();
        const contents = $('#cost_outsourcing_field_template').html().replaceAll("cost_arrange_index", cost_arrange_index).replaceAll("outsource_index", key);
        const select_id = "#cost_arranges_" + cost_arrange_index + "_cost_outsourcings_" + key + "_company_id";
        $(parent_id).append(contents);
        $(select_id).select2({
            allowClear: true
        });
    });

    $('body').on('change', '#site_id', function (e) {
        $('#id').val(null).trigger('change.select2');
    })

    $('#site_id').on('select2:select', function (e) {
        const site_id = $('#site_id').val();
        if (site_id) {
            $("#id option").attr('disabled', 'disabled');
            $("#id").find(".site-" + site_id).removeAttr("disabled");
        } else {
            $("#id option").removeAttr("disabled");
        }
    })

    $("#id").on('select2-loaded', function (e) {
        const site_id = $("#site_id").val();
        if (site_id) {
            $(".site").hide();
            $(".site-" + site_id).show();
        } else {
            $(".site").show();
        }
    })
    
    $('#add_orders').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
        const order_key = "order_key_" + $('#add_orders .nested-fields').length; 
        const new_order =  $('#add_orders .nested-fields').last().html().replaceAll('new_order_key', order_key);
        $('#add_orders .nested-fields').last().html(new_order)
    })

    $("body").on('click', '#button-order-template', function() {
        checkRenderSelector($('#add_orders'))
    });

    $("body").on('click', '.button-edit-or-create-set', function() {
        checkRenderSelector($('#add_order_templates'))
    });

    function checkRenderSelector(element) {
        if (typeof element.data('data-insert-order-template') !== 'undefined') {
            return;
        }

        const time_out = setTimeout(() => {
            if (element.attr("data-insert-order-template") == 'true') {
                element.find('select.selector').select2({
                    width: 'resolve',
                    allowClear: true
                });
                clearTimeout(time_out);
            } else {
                checkRenderSelector(element);
            }
        }, 300);
        
    }
});
