$(function(){
  address_change_postcode('#worker');
  address_change_postcode('#company');
  address_change_postcode('#site');
  address_change_postcode('#worker_emergency');

})

function address_change_postcode(target_id){
  // 住所→郵便番号
  $(target_id + '_address').on('input', function(){get_postcode(target_id + '_prefecture_id', target_id + '_address', target_id + '_postcode');});
  $(target_id + '_prefecture_id').on('change', function(){get_postcode(target_id + '_prefecture_id', target_id + '_address', target_id + '_postcode');});
  // 郵便番号→住所
  $(target_id + '_postcode').on('input', function(){get_address(target_id + '_prefecture_id', target_id + '_address', target_id + '_postcode');});
}

function get_postcode(prefecture_id, address_id, postcode_id){
  let prefecture = $(prefecture_id).val();
  let address = $(address_id).val();
  if (prefecture && address){
    $.ajax({
      type: 'GET',
      url: '/postcodes/get_postcode',
      data: { prefecture: prefecture, address: address },
      dataType: 'json'
    })
    .done(function (data) {
      if (!!data[0] && !$(postcode_id).val(data[0])) {$(postcode_id).val(data[0]);}
    })
  }
}

function get_address(prefecture_id, address_id, postcode_id){
  let postcode = $(postcode_id).val();
  if (postcode.length >= 3){
    $.ajax({
      type: 'GET',
      url: '/postcodes/get_address',
      data: { postcode: postcode },
      dataType: 'json'
    })
    .done(function (data) {
      if (!!data["prefecture"]) {
        $(prefecture_id).val(data["prefecture"]);
        $(prefecture_id).nextAll('.text').text($(prefecture_id + ' option:selected').text());
        $(prefecture_id).nextAll('.text').removeClass('default');
        $(prefecture_id).removeClass('noselection');
      }
      if (!!data["address"] && !$(address_id).val()) {
        $(address_id).val(data["address"]);
      }
    })
  }
}