require("@rails/ujs").start()
require("@rails/activestorage").start()
require('channels');
require('leaflet')
require('moment');
require('@shopify/draggable')
require('chart.js')

// Rails activestorage direct upload
import '../src/direct-upload';

import 'cleave.js'
import 'cleave.js/dist/addons/cleave-phone.jp';

// import "leaflet/dist/leaflet";

// for nested model form
import 'cocoon';

// for searching name in select tag
import '../src/select2';

// Formatter for form:input
import '../src/jpostal';

// Datepicker
import '../src/flatpickr';

// Formatter for form:input
import '../src/postcode';

import '../src/sites';

import '../src/cost_management';

// 工事台帳集計出力機能
import '../src/export_construction_ledger';