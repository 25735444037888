$(function() {
  let siteId = null;

  $('body').on('click', '.btn-export-contract', function(e) {
    e.preventDefault();
    siteId = $(this).data('site-id');
    window.$('#export-modal').modal('show');
  });

  $('input:radio[name="type"]').change(function(){
    $('#export-modal .close-modal-export').removeAttr('disabled');
  });

  $('body').on('click', '#submit-export', function() {
    $(".loader").show();
    window.$('#export-modal').modal('hide');
    const condition = $("input:radio[name='type']:checked").val();
    const url = `/sites/${siteId}/export_construction_ledger`;

    $.ajax({
      type: 'POST',
      url: url,
      data: { condition: condition },
      dataType: 'json',
      headers: { "X-CSRF-Token": $("meta[name='csrf-token']").attr("content") },
    })
    .done(function (data) {
      file_exists(data["history_id"])
    })
  });

  const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  async function file_exists(history_id) {
    await _sleep(3000);
    let url = `/sites/${siteId}/file_construction_ledger_exists`;
    $.ajax({
      type: 'GET',
      url: url,
      data: { history_id: history_id },
      dataType: 'json',
      headers: { "X-CSRF-Token": $("meta[name='csrf-token']").attr("content") },
    })
    .done(function (data) {
      if (data["file_exists"]) {
        clearTimeout(_sleep);
        open(data['url'], '_blank')
        $(".loader").hide();
      } else {
        if(data["error"]){
          alert(data["message"])
          $(".loader").hide();
        }else{
          file_exists(history_id)
        }
      }
    })
  }
})
