import {Sortable} from '@shopify/draggable';

export default function List() {
    // 順番指定機能を捨てるのも手（というか不要っぽい）
    const containerSelector = '.site-csv-columns';
    const containers = document.querySelectorAll(containerSelector);

    if (containers.length === 0) {
        return false;
    }

    const sortable = new Sortable(containers, {
        draggable: 'div.item',
        mirror: {
            appendTo: containerSelector,
            constrainDimensions: true,
        },
    }).on('sortable:stop', function(e){
        let o = e.oldContainer;
        let n = e.newContainer;
        $(n).trigger('updateLackStatus');
        $(o).trigger('updateLackStatus');
        $(n).trigger('updateNewLackStatus');
        // 出力欄から戻ってきた項目のinputを削除
        setTimeout(function(){
            $('div[data-export-columns]').find('input').remove()
        })

        setTimeout(function(){
            $('div[data-export-columns="true"]').find('.item').each(function(index, element) {
                $(this).append('<input type="hidden" value="' + element.dataset.columnName + '" name="csv_columns[' + index + ']">')
            })
        }, 1);
    });

    return sortable;
}

$(function(){
    List();
})
