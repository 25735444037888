$(function(){
  $('body').on('change', '.cost-order-form', function (e) {
    const order_form = $(this).val();
    const element_master_id = $(this).attr('id').replace('order_form', 'element_master_id');
    if (order_form) {
        $("#" + element_master_id + " option").attr('disabled', 'disabled');
        let classification = '';
        switch(order_form) {
          case 'material_cost':
            classification = '1';
            break
          case 'labor_cost':
            classification = '2';
            break
          case 'outsourcing_cost':
            classification = '3';
            break
          default:
            classification = '4';
        }
        $("#" + element_master_id).find(".element-master-" + classification).removeAttr("disabled");
    } else {
      $("#" + element_master_id + " option").removeAttr("disabled");
    }
    $("#" + element_master_id).val(null).trigger('change.select2');
  })

  $('.labor_cost_sub_order_fields').on('cocoon:after-insert', function(e, insertedItem) {
    const parent_id = $(this).attr('id');
    const last_month = $("#" + parent_id).attr('data-month');
    const sub_acceptance_name = $('#' + parent_id + ' tr:last .sub_acceptance_amount input').attr('name').replace('[0][amount]', '');
    const sub_acceptance_id =  $('#' + parent_id + ' tr:last .sub_acceptance_amount input').attr('id').replace('0_amount', ''); 
    const sub_acceptance_index = sub_acceptance_id.match(/\d+/g)[1];
    $('#' + parent_id + ' tr:last').attr('data-index', sub_acceptance_index);

    if (last_month) {
      let month_index = $('#' + parent_id + ' tr:last .sub_acceptance_amount').length - 1;
       
      const sub_acceptance_last_month = $('#' + sub_acceptance_id + month_index + '_month').val();
      const diff_month = getMonthDifference(new Date(sub_acceptance_last_month), new Date(last_month));
      
      let sub_acceptance_last_date = new Date(sub_acceptance_last_month);

      for(let i = 0; i < diff_month; i++) {
        month_index++;
        sub_acceptance_last_date.setMonth(sub_acceptance_last_date.getMonth() + 1);
        let month = '' + parseInt(sub_acceptance_last_date.getFullYear()) + '-' + parseInt(sub_acceptance_last_date.getMonth() + 1) + '-01';
        
        let sub_acceptance_amount_name = sub_acceptance_name + '[' + month_index + '][amount]';
        let sub_acceptance_amount_id = sub_acceptance_id + month_index + '_amount';
        let sub_acceptance_month_name = sub_acceptance_name + '[' + month_index + '][month]';
        let sub_acceptance_month_id = sub_acceptance_id + month_index + '_month';

        $('#' + parent_id + ' tr:last').append('<input type="hidden" value="' + month + '" name="' + sub_acceptance_month_name + '" id="' + sub_acceptance_month_id + '">')
        if (insertedItem.hasClass('add_color_selected_cell_cost_management')) {
          $('#' + parent_id + ' tr:last').append('<td><input class="fuga input-comma-num js-color-fuga add_color_selected_cell_cost_management" style="width: 113px;" data-group="" type="text" name="'+ sub_acceptance_amount_name + '" id="'+ sub_acceptance_amount_id + '"></td><td><input type="text" class="fuga input-comma-num js-color-fuga add_color_selected_cell_cost_management" style="width: 50px;" value="" data-volume="" readonly=""><div class="ui mini button purchase_order blue" data-notice="sample" data-worker-id="" style="padding: 5px;">検収</div></td>')
        } else {
          $('#' + parent_id + ' tr:last').append('<td><input class="fuga input-comma-num js-color-fuga" style="width: 113px;" data-group="" type="text" name="'+ sub_acceptance_amount_name + '" id="'+ sub_acceptance_amount_id + '"></td><td><input type="text" class="fuga js-color-fuga" style="width: 50px;" value="" data-volume="" readonly=""><div class="ui mini button purchase_order blue" data-notice="sample" data-worker-id="" style="padding: 5px;">検収</div></td>')
        }
      }
    }
  })

  function getMonthDifference(startDate, endDate) {
      return (
          endDate.getMonth() -
          startDate.getMonth() +
          12 * (endDate.getFullYear() - startDate.getFullYear())
      );
  }
})
